import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { Post } from "../models/post";
import { PostStats } from "../models/post-stats";
import { SignedAcceptanceTerm } from "../models/signed-acceptance-term";
import { Poll } from "../models/poll";
import { PollVote } from "../models/poll-vote";

@Injectable({
    providedIn: 'root'
})

export class PostService {
    constructor(private http: HttpClient) { }

    getPosts(params?) {
        return this.http.get<Post[]>(`${environment.API}posts`, { params }).pipe();
    }

    createPost(body) {
        return this.http.post<any>(`${environment.API}posts`, body).pipe();
    }

    updatePost(postID: number, body) {
        return this.http.put<any>(`${environment.API}posts/${postID}`, body).pipe();
    }

    deletePost(postID: number) {
        return this.http.delete<any>(`${environment.API}posts?id=${postID}`).pipe();
    }

    readPost(postID: number, body) {
        return this.http.put<any>(`${environment.API}posts/${postID}/read`, body).pipe();
    }

    ratePost(postID: number, body) {
        return this.http.put<any>(`${environment.API}posts/${postID}/like`, body).pipe();
    }

    acceptPostAcceptanceTerm(body) {
        return this.http.post<any>(`${environment.API}posts/accept-acceptance-term`, body).pipe();
    }

    getPostStats(params?) {
        return this.http.get<PostStats>(`${environment.API}posts/stats`, { params }).pipe();
    }

    getSignedAcceptanceTerm(params) {
        return this.http.get<SignedAcceptanceTerm>(`${environment.API}posts/signed-acceptance-term`, { params }).pipe();
    }

    getPolls(params?) {
        return this.http.get<Poll[]>(`${environment.API}posts/polls`, { params }).pipe();
    }

    createPoll(body) {
        return this.http.post<any>(`${environment.API}posts/polls`, body).pipe();
    }

    deletePoll(pollID: number) {
        return this.http.delete<any>(`${environment.API}posts/polls?id=${pollID}`).pipe();
    }

    voteInPoll(body) {
        return this.http.post<any>(`${environment.API}posts/polls/vote`, body).pipe();
    }
    
    getPollVotes(params) {
        return this.http.get<PollVote[]>(`${environment.API}posts/polls/votes`, { params }).pipe();
    }
}