import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { MaterialModule } from '../view-models/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MessageDialogComponent } from './message-dialog/message-dialog.component';
import { StatusChipComponent } from './status-chip/status-chip.component';
import { FormsModule } from '@angular/forms';
import { MatIconModule } from '@angular/material/icon';
import { EmptyResultsComponent } from './empty-results/empty-results.component';
import { CpfCnpjPipe } from './cnpj.pipe';
import { CropImageComponent } from './crop-image/crop-image.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { DisabledFeatureComponent } from './disabled-feature/disabled-featurecomponent';
import { PhonePipe } from './phone-number.pipe';
import { SafePipe } from './safe-pipe';
import { MessageDecryptionPipe } from './message-decryption-pipe';
import { ChatDecryptionPipe } from './chat-decryption-pipe';
import { FrameFileComponent } from './frame-file/frame-file.component';
import { LinkPipe } from './link.pipe';
import { SnackbarComponent } from './snackbar-popup/snackbar-popup.component';
import { PollCardComponent } from './poll-card/poll-card.component';
import { PostCardComponent } from './post-card/post-card.component';
import { NgxVisibilityModule } from 'ngx-visibility';



@NgModule({
  declarations: [
    ConfirmDialogComponent,
    MessageDialogComponent,
    StatusChipComponent,
    EmptyResultsComponent,
    CpfCnpjPipe,
    CropImageComponent,
    DisabledFeatureComponent,
    PhonePipe,
    SafePipe,
    MessageDecryptionPipe,
    ChatDecryptionPipe,
    FrameFileComponent,
    LinkPipe,
    SnackbarComponent,
    PostCardComponent,
    PollCardComponent
  ],
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    FormsModule,
    MatIconModule,
    ImageCropperModule,
    NgxVisibilityModule,
  ],
  exports: [
    StatusChipComponent,
    EmptyResultsComponent,
    CpfCnpjPipe,
    CropImageComponent,
    DisabledFeatureComponent,
    PhonePipe,
    SafePipe,
    MessageDecryptionPipe,
    ChatDecryptionPipe,
    FrameFileComponent,
    LinkPipe,
    SnackbarComponent,    
    PostCardComponent,
    PollCardComponent
  ]
})
export class HelpersModule { }