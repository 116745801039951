<div class="container-content">
    <div class="fixed-actions-bar">
        <div class="actions-bar">
            <mat-form-field class="full-container textbox" appearance="fill">
                <input #searchInput style="padding-left: 10px;" matInput autocomplete="off"
                    (keyup)="applyFilter($event.target.value)" placeholder="Pesquisa">
                <mat-icon matSuffix style="padding-right: 10px;">search</mat-icon>
            </mat-form-field>
            <button mat-button color="primary" *ngIf="allowPostManagement" [matMenuTriggerFor]="postOptionsMenu">
                <mat-icon>create</mat-icon> Nova publicação
            </button>
            <mat-menu #postOptionsMenu="matMenu">
                <button mat-menu-item (click)="addPost()">
                    <mat-icon>view_carousel</mat-icon>
                    <span>Publicação</span>
                </button>
                <button mat-menu-item (click)="addPoll()">
                    <mat-icon>poll</mat-icon>
                    <span>Enquete</span>
                </button>
            </mat-menu>
        </div>
        <mat-chip-list>
            <mat-chip (click)="selectFilter(chip); " *ngFor="let chip of chips" [selected]="chip.selected">
                {{ chip.label }}
            </mat-chip>
        </mat-chip-list>
    </div>
    <div style="margin-top: 140px;">
        <div *ngFor="let post of posts.filteredData" class="post" fxLayout="column"
            fxLayoutAlign="space-between stretch">
            <app-poll-card [poll]="post" [allowEditingAnyPost]="allowEditingAnyPost"
                [allowPostManagement]="allowPostManagement" (onDeleteRequest)="deletePoll($event)"
                (onVoteRequest)="voteInPoll($event.poll, $event.option)" (onViewVotesRequest)="viewPollVotes($event)"
                *ngIf="isPoll(post)"></app-poll-card>
            <app-post-card [post]="post" [allowEditingAnyPost]="allowEditingAnyPost"
                [allowPostManagement]="allowPostManagement" (onEditRequest)="editPost($event)"
                (onDeleteRequest)="deletePost($event)" (onPostRate)="ratePost($event)"
                (onVisibilityUpdate)="updatePostVisibility($event.post, $event.ev)"
                *ngIf="isPost(post)"></app-post-card>
        </div>
        <app-empty-results *ngIf="posts.filteredData.length <= 0"></app-empty-results>
    </div>
    <button class="mat-fab" mat-fab color="primary" [matBadge]="unreadChats" matBadgeColor="accent"
        [matBadgeHidden]="unreadChats <= 0" (click)="goToMessages()" matTooltip="Minhas conversas"
        matTooltipPosition="left" *ngIf="allowMessagesAccess">
        <mat-icon>forum</mat-icon>
    </button>
</div>