<body>
  <div class="main-div">
    <div *ngIf="tokenStatus == TokenStatus.Validating">
      Validando token...
    </div>
    <mat-card class="default-card" *ngIf="tokenStatus == TokenStatus.Valid">
      <mat-card-header>
        <div mat-card-avatar class="default-card-header-image"></div>
        <mat-card-title>Nova senha</mat-card-title>
        <mat-card-subtitle>Digite e confirme sua nova senha nos campos abaixo.</mat-card-subtitle>
      </mat-card-header>
      <mat-card-content>
        <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
        <form [formGroup]="form">
          <p>
            <mat-form-field class="full-container">
              <mat-label>Senha</mat-label>
              <input type="password" matInput formControlName="password">
              <mat-error>Senha muito curta</mat-error>
            </mat-form-field>
          </p>
          <p>
            <mat-form-field class="full-container">
              <mat-label>Confirmar Senha</mat-label>
              <input type="password" matInput formControlName="confirmPassword">
              <mat-error>
                {{ form.value.confirmPassword.length < 8 ? 'Senha muito curta' : 'Senhas não coincidem' }} </mat-error>
            </mat-form-field>
          </p>
        </form>
        <label>Recomendações</label>
        <ul>
          <li>Evite dados pessoais públicos.</li>
          <li>Evite números ou letras em sequência.</li>
          <li>Utilize letras maiúsculas e minúsculas.</li>
          <li>Utilize 8 caracteres ou mais.</li>
        </ul>
      </mat-card-content>

      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="loading">Confirmar</button>
        <a mat-stroked-button routerLink="/login">Voltar ao login</a>
      </mat-card-actions>
    </mat-card>
    <mat-card class="default-card" *ngIf="tokenStatus == TokenStatus.Invalid">
      <mat-card-header>
        <mat-icon mat-card-avatar class="icon-md">warning</mat-icon>
        <mat-card-title>Código Inválido</mat-card-title>
        <mat-card-subtitle>O código inserido é inválido ou expirou. É possível obter outro clicando em <i><b>Reenviar
              Código</b></i>.
        </mat-card-subtitle>
      </mat-card-header>
      <mat-card-actions>
        <button mat-raised-button color="primary" (click)="resendCode()" [disabled]="loading">Reenviar
          Código</button>
        <a mat-stroked-button routerLink="/login">Voltar ao login</a>
      </mat-card-actions>
    </mat-card>
  </div>

  <img src="assets/images/logo.png" class="logo-image">
</body>