import { PollOption } from "./poll-option";

export class Poll {
    id: number;
    postDate: Date;
    dueDate: Date;
    title: string;
    hasVoted: boolean;
    allowVoting: boolean;
    allowDeletion: boolean;
    isExpired: boolean;
    createdBy?: string;
    options: PollOption[];

    constructor(poll: Poll) {
        for (const key in poll) {
            if (poll.hasOwnProperty(key)) {
                this[key] = poll[key];
            }
        }
    }
}