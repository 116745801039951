import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { VerificationType } from '../verify-code/verify-code.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form!: UntypedFormGroup;
  siteKey: string = environment.siteKey;
  showCaptcha: boolean = true;
  loading = false;
  submitted = false;
  returnUrl!: string;

  constructor(private route: ActivatedRoute, private router: Router,
    private userService: UserService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      login: new UntypedFormControl('', [Validators.required]),
      password: new UntypedFormControl('', [Validators.required]),
      reCaptcha: new UntypedFormControl(null, [Validators.required])
    });

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  onSubmit() {
    this.submitted = true;

    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.userService.login(this.form.value).subscribe({
      next: authenticationSession => {
        if (authenticationSession.is2FAEnabled) {
          this.router.navigate(['/two-factor-authentication'], { queryParams: { sessionToken: authenticationSession.sessionToken, login: this.form.value.login } });
        }
        else {
          this.userService.validateSessionToken({ sessionToken: authenticationSession.sessionToken }).subscribe({
            next: () => {
              const returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
              this.router.navigateByUrl(returnUrl);
            },
            error: error => {
              this.notificationService.error(error);
            }
          });
        }
      },
      error: error => {
        this.notificationService.error(error);
        this.loading = false;
        this.form.patchValue({ reCaptcha: null });

        this.showCaptcha = false;

        setTimeout(() => {
          this.showCaptcha = true;
        });
      }
    });
  }

  forgotPassword() {
    this.router.navigate(['/send-code'], { queryParams: { type: VerificationType.ResetPassword } });
  }
}