<body>
    <div class="main-div">
        <mat-card class="default-card">
            <mat-card-header>
                <div mat-card-avatar class="default-card-header-image"></div>
                <mat-card-title>Código de Verificação</mat-card-title>
                <mat-card-subtitle>Insira seu e-mail ou CPF de acesso, para que possamos enviar o código de verificação.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                <form [formGroup]="form">
                    <p>
                        <mat-form-field class="full-container">
                            <mat-label>Email ou CPF</mat-label>
                            <input type="text" matInput formControlName="email">
                            <mat-error>Preencha o campo acima</mat-error>
                        </mat-form-field>
                        <ngx-recaptcha2 class="full-container" *ngIf="showCaptcha" #captchaElem [siteKey]="siteKey"
                            [useGlobalDomain]="false" size="normal" hl="pt" theme="light" type="image"
                            formControlName="reCaptcha">
                        </ngx-recaptcha2><br>
                    </p>
                </form>
            </mat-card-content>

            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="loading">Envie-me
                    instruções</button>
                <button mat-stroked-button (click)="alreadyHaveCode()">Já possui um código?</button>
            </mat-card-actions>
        </mat-card>
    </div>

    <img src="assets/images/logo.png" class="logo-image">
</body>