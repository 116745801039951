import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import moment from 'moment';
import { AdminService } from 'src/app/services/admin.service';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-add-poll',
    templateUrl: './add-poll.component.html',
    styleUrls: ['./add-poll.component.scss']
})

export class AddPollComponent implements OnInit {
    form: UntypedFormGroup;
    options: string[] = [];
    maxOptionsPerPoll: number;
    minDate: Date = moment().add(1, 'days').toDate();

    constructor(private dialogRef: MatDialogRef<AddPollComponent>, private notificationService: NotificationService, private adminService: AdminService) { }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            title: new UntypedFormControl(null, [Validators.required]),
            dueDate: new UntypedFormControl(null, [Validators.required]),
            dueTime: new UntypedFormControl('23:59', [Validators.required]),
            allowRevoting: new UntypedFormControl(false)
        });

        this.adminService.getParameterByCode({ code: 'Sys-MaxOptionsPerPoll' }).subscribe({
            next: parameter => {
                this.maxOptionsPerPoll = Number.parseInt(parameter.value);
            },
            error: error => {
                this.maxOptionsPerPoll = 0;
                this.notificationService.error(error);
            }
        });
    }

    save() {
        if (this.form.invalid) {
            this.notificationService.warning('Existem campos não preenchidos.');
            return;
        }

        if (this.options?.length <= 0) {
            this.notificationService.warning('Insira as opções');
            return;
        }

        let poll = {
            title: this.form.value.title,
            dueDate: new Date(this.form.value.dueDate),
            dueTime: this.form.value.dueTime,
            allowRevoting: this.form.value.allowRevoting,
            options: this.options.map(o => ({ description: o }))
        };

        this.dialogRef.close(poll);
    }

    close() {
        this.dialogRef.close();
    }

    addOption() {
        if (this.options?.length >= this.maxOptionsPerPoll) {
            this.notificationService.warning(`Limite de opções atingido (${this.maxOptionsPerPoll}).`);
            return;
        }

        this.options.push(`Opção ${this.options?.length + 1}`);
        this.options = this.options.filter(o => o);
    }

    updateOption(index: number, value: string) {
        this.options[index] = value;
    }

    removeOption(index: number) {
        this.options.splice(index, 1);
        this.options = this.options.filter(o => o);
    }
}
