<div fxLayout="row" fxLayoutAlign="space-between center">
    <h1 mat-dialog-title>{{user.name}}</h1>
    <button mat-button aria-label="aplicar" (click)="save()" matTooltip="Aplica as alterações.">
        Aplicar
        <mat-icon>done</mat-icon>
    </button>
</div>
<mat-dialog-content [formGroup]="form"> 
    <mat-form-field class="half-container">
        <mat-label>Nova Senha</mat-label>
        <input type="password" matInput placeholder="Nova Senha" matInput formControlName="password" (keyup)="verifyStrength()" cdkFocusInitial>
    </mat-form-field> 
    <mat-form-field class="half-container">
        <mat-label>Confirmar Senha</mat-label>
        <input type="password" matInput placeholder="Confirmar Senha" matInput formControlName="confirmPassword">
    </mat-form-field>
    <small>Força</small>
    <mat-progress-bar [value]="strength"></mat-progress-bar>
</mat-dialog-content>