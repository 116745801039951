import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-frame-file',
    templateUrl: './frame-file.component.html',
    styleUrls: ['frame-file.component.scss']
})

export class FrameFileComponent {

    @Input()
    get filePath(): string { return this._filePath; }
    set filePath(filePath: string) {
        this._filePath = (filePath && filePath.trim()) || '<no text set>';
    }
    private _filePath = '';

    @Input()
    get fileType(): string { return this._fileType; }
    set fileType(fileType: string) {
        this._fileType = (fileType && fileType.trim()) || '<no text set>';
    }
    private _fileType = '';

    @Input()
    get allowHoverAction(): boolean { return this._allowHoverAction }
    set allowHoverAction(allowHoverAction: boolean) {
        this._allowHoverAction = allowHoverAction;
    }
    private _allowHoverAction = true;
    
    get filePreviewType(): FileTypes {
        switch (this._fileType) {
            case 'image/png':
                return FileTypes.Image;
            case 'image/jpeg':
                return FileTypes.Image;
            case 'image/jpg':
                return FileTypes.Image;
            case 'video/mp4':
                return FileTypes.Video;
            case 'application/pdf':
                return FileTypes.PDF;
            default:
                return FileTypes.Unknown;
        }
    }

    get FileTypes(): typeof FileTypes {
        return FileTypes;
    }
}

enum FileTypes {
    Image,
    Video,
    PDF,
    Unknown,
}