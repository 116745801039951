import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: []
})
export class StatusChipComponent {

  @Input()
  get text(): string { return this._text; }
  set text(text: string) {
    this._text = (text && text.trim()) || '<no text set>';
  }
  private _text = '';

  get color(): string { return this.colors[this.text]; }

  private colors = {
    ['Conectado']: 'success',
    ['Desconectado']: 'danger',
    ['Aguardando Ativação']: 'other',
    ['Expirado']: 'danger',
    ['Cancelada']: 'warning',
    ['Eterno']: 'info',
    ['Agendado']: 'other'
  }
}
