import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent, ConfirmDialogModel } from 'src/app/helpers/confirm-dialog/confirm-dialog.component';
import { AcceptanceTerm } from 'src/app/models/acceptance-term';
import { SignedAcceptanceTerm } from 'src/app/models/signed-acceptance-term';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
  selector: 'app-view-acceptance-term',
  templateUrl: './view-acceptance-term.component.html',
  styleUrls: ['./view-acceptance-term.component.scss'],
})
export class ViewAcceptanceTermComponent implements OnInit {
  acceptanceTerm?: AcceptanceTerm;
  signedAcceptanceTerm?: SignedAcceptanceTerm;
  showExportButton: boolean = false;
  showAcceptButton: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ViewAcceptanceTermComponent>, private notificationService: NotificationService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.acceptanceTerm = this.data?.acceptanceTerm;
    this.signedAcceptanceTerm = this.data?.signedAcceptanceTerm;
    this.showExportButton = this.data?.showExportButton ?? false;
    this.showAcceptButton = this.data?.showAcceptButton ?? false;
  }

  acceptTerm() {
    const message = `Confima a aceitação deste termo?`;
    const dialogData = new ConfirmDialogModel("Confirmação", message);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, { data: dialogData });

    dialogRef.afterClosed().subscribe({
      next: result => {
        if (result) {
          this.dialogRef.close(true);
        }
      }
    });
  }
}