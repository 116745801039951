import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgModule, LOCALE_ID } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from './view-models/material.module';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { registerLocaleData } from '@angular/common';
import { refreshTokenProvider } from './helpers/app.initializer';
import { LoginComponent } from './public/login/login.component';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { NGX_MAT_DATE_FORMATS } from '@angular-material-components/datetime-picker';
import { NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular-material-components/moment-adapter';
import { HttpRequestInterceptor } from './helpers/http-request.interceptor';
import { NgxMaskModule } from 'ngx-mask';
import ptBr from '@angular/common/locales/pt';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatCardModule } from '@angular/material/card';
import { MatMenuModule } from '@angular/material/menu';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { LayoutModule } from '@angular/cdk/layout';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { CustomMatPaginatorIntl } from './custom-materials/custom-paginator';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HelpersModule } from './helpers/helpers.module';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { HeaderComponent } from './header/header.component';
import { SendCodeComponent } from './public/send-code/send-code.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { VerifyCodeComponent } from './public/verify-code/verify-code.component';
import { AddPostComponent } from './home/add-post/add-post.component';
import { MobileWarningComponent } from './public/mobile-warning/mobile-warning.component';
import { NgxCaptchaModule } from 'ngx-captcha';
import { SettingsModule } from './settings/settings.module';
import { QuillModule } from 'ngx-quill';
import { TwoFactorAuthenticationComponent } from './public/two-factor-authentication/two-factor-authentication.component';
import { RecaptchaDialogComponent } from './public/recaptcha-dialog/recaptcha-dialog.component';
import { NgxVisibilityModule } from 'ngx-visibility';
import { AddPollComponent } from './home/add-poll/add-poll.component';
import { ViewPollVotesComponent } from './home/view-poll-votes/view-poll-votes.component';
registerLocaleData(ptBr);

const VT_FORMATS = {
  parse: {
    dateInput: 'DD/MM/YYYY',
  },
  display: {
    dateInput: 'DD/MM/YYYY',
    monthYearLabel: 'MM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MM YYYY',
  },
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    HomeLayoutComponent,
    LoginLayoutComponent,
    HeaderComponent,
    HomeComponent,
    SendCodeComponent,
    ResetPasswordComponent,
    VerifyCodeComponent,
    AddPostComponent,
    MobileWarningComponent,
    TwoFactorAuthenticationComponent,
    RecaptchaDialogComponent,
    AddPollComponent,
    ViewPollVotesComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(),
    MatGridListModule,
    MatCardModule,
    MatMenuModule,
    MatIconModule,
    MatButtonModule,
    LayoutModule,
    ImageCropperModule,
    HelpersModule,
    NgxCaptchaModule,
    SettingsModule,
    NgxVisibilityModule,
    QuillModule.forRoot({
      modules: {
        syntax: false,
        toolbar: [
          ['bold', 'italic', 'underline', 'strike'],
          ['blockquote'],
          [{ 'header': 1 }, { 'header': 2 }],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          [{ 'indent': '-1' }, { 'indent': '+1' }],
          [{ 'size': ['small', false, 'large'] }],
          [{ 'font': [] }],
          [{ 'align': [] }],
          ['link']
        ]
      }
    })
  ],
  providers: [
    refreshTokenProvider,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: HttpRequestInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'pt' },
    { provide: MAT_DATE_FORMATS, useValue: VT_FORMATS },
    { provide: NGX_MAT_DATE_FORMATS, useValue: VT_FORMATS },
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: NGX_MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'outline', floatLabel: 'always' } },
    { provide: MatPaginatorIntl, useClass: CustomMatPaginatorIntl }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
