<mat-toolbar color="primary">

  <button mat-icon-button (click)="sidenav.toggle()" *ngIf="allowSidenavAccess">
    <mat-icon>menu</mat-icon>
  </button>
  <a mat-button routerLink="." (click)="sidenav.close()">
    <img src="/assets/images/icon.png" width="30" height="30">
    Minha Coop</a>

  <span class="menu-spacer"></span>

  <mat-chip-list ng-if="!loggedUser">
    <mat-chip color="primary" selected>{{ loggedUser?.name }}
    </mat-chip>
  </mat-chip-list>
  <img [class]="loggedUser?.image ? 'profile-picture' : 'default-avatar'"
    [src]="loggedUser?.image ?? (authUser.isAssociate ? 'assets/images/default-associate.png' : 'assets/images/default-admin.png')"
    [matMenuTriggerFor]="menu">
  <mat-icon *ngIf="notificationCount > 0" class="notification-dot">notifications_active</mat-icon>
  <mat-menu #menu="matMenu">
    <a mat-menu-item routerLink="/settings/profile" (click)="sidenav.close()">
      <mat-icon>account_circle</mat-icon>
      <span>Meu perfil</span>
    </a>
    <a mat-menu-item routerLink="/settings/notifications" (click)="sidenav.close()">
      <span style="margin-right: 16px;">
        <mat-icon style="margin: 0px">notifications</mat-icon>
        <span [matBadge]="notificationCount" matBadgeColor="primary" matBadgeSize="small"
          [matBadgeHidden]="notificationCount <= 0"></span>
      </span>
      <span>Notificações</span>
    </a>
    <a mat-menu-item routerLink="/messaging" (click)="sidenav.close()" *ngIf="allowMessagesAccess">
      <mat-icon>forum</mat-icon>
      <span>Mensagens</span>
    </a>
    <button mat-menu-item (click)="logout()" (click)="sidenav.close()">
      <mat-icon>exit_to_app</mat-icon>
      <span>Sair</span>
    </button>
  </mat-menu>
</mat-toolbar>
<mat-sidenav-container fullscreen class="container">
  <mat-sidenav #sidenav mode="over" class="sidenav">
    <mat-nav-list>
      <mat-accordion>
        <mat-expansion-panel *ngFor="let sidenavParent of sidenavParents" class="mat-elevation-z0 sidenav-expansion-panel" #expansion hideToggle>
          <mat-expansion-panel-header tabindex="-1">
            <mat-panel-title>
              <mat-icon
                [class]="expansion.expanded ? 'toggle toggle-opened' : 'toggle toggle-closed'">chevron_right</mat-icon>
              <h2 matSubheader>{{ sidenavParent.title }}</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <a *ngFor="let item of sidenavParent.items" mat-list-item (click)="navigate(item)">
            <mat-icon matListIcon style="padding: 10px"> {{ item.icon }} </mat-icon>
            <label>{{ item.title }}</label>
          </a>
        </mat-expansion-panel>
      </mat-accordion>
    </mat-nav-list>
  </mat-sidenav>
  <mat-sidenav-content>
    <router-outlet></router-outlet>
  </mat-sidenav-content>
</mat-sidenav-container>