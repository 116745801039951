import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MustMatchValidator } from 'src/app/helpers/must-match.validator';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { VerificationType } from '../verify-code/verify-code.component';

enum TokenStatus {
  Validating,
  Valid,
  Invalid
}

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  TokenStatus = TokenStatus;
  tokenStatus = TokenStatus.Validating;
  code = null;
  form: UntypedFormGroup;
  loading = false;

  constructor(private userService: UserService, private notificationService: NotificationService,
    private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.form = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, { validators: MustMatchValidator("password", "confirmPassword") });

    const code = this.route.snapshot.queryParams['code'];

    this.router.navigate([], { relativeTo: this.route, replaceUrl: true });

    this.userService.validateCode(code, VerificationType.ResetPassword).subscribe({
      next: () => {
        this.code = code;
        this.tokenStatus = TokenStatus.Valid;
      },
      error: () => {
        this.tokenStatus = TokenStatus.Invalid;
      }
    });

  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }

    this.loading = true;

    this.userService.resetPassword(this.code, this.form.value.password, this.form.value.confirmPassword).subscribe({
      next: () => {
        this.notificationService.sucess("A senha foi alterada.");
        this.router.navigate(['../login'], { relativeTo: this.route });
        this.loading = false;
      },
      error: error => {
        this.notificationService.error(error);
        this.loading = false;
      }
    });
  }

  resendCode() {
    this.router.navigate(['/send-code'], { queryParams: { type: VerificationType.ResetPassword } });
  }
}
