<div fxLayout="row" fxLayoutAlign="space-between start">
    <h1 mat-dialog-title>Votos da Enquete</h1>
    <button mat-button (click)="close()">Fechar</button>
</div>
<mat-form-field>
    <mat-label>Filtrar opção</mat-label>
    <mat-select [(value)]="selectedOption" (selectionChange)="getVotes()">
        <mat-option *ngFor="let option of options" [value]="option">{{ option.description }}</mat-option>
    </mat-select>
</mat-form-field>
<mat-dialog-content>
    <mat-card *ngIf="votes?.length > 0">
        <div class="mat-elevation-z4 mat-table">
            <table mat-table [dataSource]="votes">
                <ng-container matColumnDef="user">
                    <th mat-header-cell *matHeaderCellDef> Usuário </th>
                    <td mat-cell *matCellDef="let element"> {{ element.user }} </td>
                </ng-container>

                <ng-container matColumnDef="pollOption">
                    <th mat-header-cell *matHeaderCellDef> Votou em </th>
                    <td mat-cell *matCellDef="let element"> {{ element.pollOption }} </td>
                </ng-container>

                <ng-container matColumnDef="entryDate">
                    <th mat-header-cell *matHeaderCellDef> Voto registrado em </th>
                    <td mat-cell *matCellDef="let element">
                        <label>{{ element.entryDate | date:"dd/MM/yyyy HH:mm" }}</label>
                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
        </div>
    </mat-card>
    <app-empty-results *ngIf="votes?.length <= 0"></app-empty-results>
    <br>
</mat-dialog-content>