import { Component, OnInit } from '@angular/core';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';
import { VerificationType } from '../verify-code/verify-code.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-send-code',
  templateUrl: './send-code.component.html',
  styleUrls: ['./send-code.component.scss']
})
export class SendCodeComponent implements OnInit {
  siteKey: string = environment.siteKey;
  form: UntypedFormGroup;
  type: VerificationType;
  loading = false;
  showCaptcha: boolean = true;

  constructor(private formBuilder: FormBuilder, private userService: UserService, private notificationService: NotificationService,
    private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.type = this.route.snapshot.queryParams['type'];

    this.form = this.formBuilder.group({
      email: ['', [Validators.required]],
      reCaptcha: [null, [Validators.required]]
    });
  }

  onSubmit() {
    if (this.form.invalid || this.type == null) {
      return;
    }

    this.loading = true;
    this.userService.sendCode(this.form.value.email, this.form.value.reCaptcha).subscribe({
      next: () => {
        this.notificationService.info("Instruções foram enviadas para o seu e-mail ou número de telefone.");
        this.router.navigate(['/verify-code'], { queryParams: { type: this.type } });
        this.loading = false;
      },
      error: error => {
        this.notificationService.error(error);
        this.loading = false;
        this.form.patchValue({ reCaptcha: null });

        this.showCaptcha = false;

        setTimeout(() => {
          this.showCaptcha = true;
        });
      }

    });
  }

  alreadyHaveCode() {    
    this.router.navigate(['/verify-code'], { queryParams: { type: this.type } });
  }
}
