import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TwoFactorAuthenticator } from 'src/app/models/two-factor-authenticator';

@Component({
    selector: 'app-configure-two-factor-authenticator',
    templateUrl: './configure-two-factor-authenticator.component.html',
    styleUrls: ['./configure-two-factor-authenticator.component.scss']
})

export class ConfigureTwoFactorAuthenticatorComponent implements OnInit {
    twoFactorAuthenticator: TwoFactorAuthenticator;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ConfigureTwoFactorAuthenticatorComponent>) { }

    ngOnInit() {
        this.twoFactorAuthenticator = this.data?.twoFactorAuthenticator;
    }

    close() {
        this.dialogRef.close();
    }
}
