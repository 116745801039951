import { Component, EventEmitter, Input, Output } from "@angular/core";
import { Poll } from "src/app/models/poll";
import { PollOption } from "src/app/models/poll-option";

@Component({
    selector: 'app-poll-card',
    templateUrl: './poll-card.component.html',
    styleUrls: ['poll-card.component.scss']
})

export class PollCardComponent {
    @Input()
    get poll(): Poll { return this._poll; }
    set poll(poll: Poll) {
        this._poll = poll;
    }
    private _poll: Poll;

    @Input()
    get allowEditingAnyPost(): boolean { return this._allowEditingAnyPost; }
    set allowEditingAnyPost(allowEditingAnyPost: boolean) {
        this._allowEditingAnyPost = allowEditingAnyPost;
    }
    private _allowEditingAnyPost = true;

    @Input()
    get allowPostManagement(): boolean { return this._allowPostManagement; }
    set allowPostManagement(allowPostManagement: boolean) {
        this._allowPostManagement = allowPostManagement;
    }
    private _allowPostManagement = true;

    @Output() onViewVotesRequest: EventEmitter<Poll> = new EventEmitter<Poll>();

    viewVotes() {
        this.onViewVotesRequest.emit(this._poll);
    }

    @Output() onDeleteRequest: EventEmitter<Poll> = new EventEmitter<Poll>();

    deletePoll() {
        this.onDeleteRequest.emit(this._poll);
    }

    @Output() onVoteRequest: EventEmitter<{ poll: Poll, option: PollOption }> = new EventEmitter<{ poll: Poll, option: PollOption }>();

    voteInPoll(option: PollOption) {
        this.onVoteRequest.emit({ poll: this._poll, option: option });
    }
}