import { SidenavItem } from "./sidenav-item";

export class SidenavParent {
    title: string;
    items: SidenavItem[];
    adminsOnlyTab: boolean;

    constructor (title: string, items: SidenavItem[]) {
        this.title = title;
        this.items = items;
        this.adminsOnlyTab = items.filter(i => !i.adminsOnly).length <= 0;
    }
}