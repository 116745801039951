import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { AppVersion } from "../models/app-version";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})

export class AppVersionsService {
    constructor(private http: HttpClient) { }

    getAppVersions(params?) {
        return this.http.get<AppVersion[]>(`${environment.API}appVersions`, { params }).pipe();
    }

    getLatestAppVersion() {
        return this.http.get<AppVersion>(`${environment.API}appVersions/latest`).pipe();
    }

    createAppVersion(body) {
        return this.http.post<any>(`${environment.API}appVersions`, body).pipe();
    }

    updateAppVersion(code: number, body) {
        return this.http.put<any>(`${environment.API}appVersions/${code}`, body).pipe();
    }

    deleteAppVersion(params) {
        return this.http.delete<any>(`${environment.API}appVersions`, { params }).pipe();
    }

    downloadAppVersion(params) {
        return this.http.get(`${environment.API}appVersions/download`, { params, responseType: "arraybuffer" }).pipe();
    }
}
