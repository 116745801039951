<body>
    <div class="main-div">
        <mat-card class="default-card">
            <mat-card-header>
                <div mat-card-avatar class="default-card-header-image"></div>
                <mat-card-title>Autenticação de dois fatores</mat-card-title>
                <mat-card-subtitle>Insira, no campo abaixo, o código de autenticação de dois fatores.
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-spinner *ngIf="loading" diameter="20"></mat-spinner>
                <form [formGroup]="form">
                    <p>
                        <mat-form-field class="full-container">
                            <mat-label>Código</mat-label>
                            <input type="text" matInput formControlName="twoFactorAuthenticatorPIN">
                            <mat-error>Preencha o campo acima</mat-error>
                        </mat-form-field>
                    </p>
                </form>
            </mat-card-content>

            <mat-card-actions>
                <button mat-raised-button color="primary" (click)="onSubmit()" [disabled]="loading">Confirmar</button>
                <button mat-stroked-button (click)="lostAuthenticator()">Não tenho mais acesso</button>
            </mat-card-actions>
        </mat-card>
    </div>

    <img src="assets/images/logo.png" class="logo-image">
</body>