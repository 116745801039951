<h1 mat-dialog-title *ngIf="!isInPreviewMode">{{ editMode ? 'Editar' : 'Nova' }} Publicação</h1>
<div [formGroup]="form">
    <div *ngIf="!isInPreviewMode" fxLayout="row" fxLayoutAlign="none start" fxLayoutGap="8px">
        <div class="full-container">
            <mat-form-field class="full-container">
                <mat-label>Título</mat-label>
                <input matInput type="text" formControlName="title" maxlength="100">
                <mat-hint align="end">{{ form.value.title?.length ?? 0 }} / 100</mat-hint>
                <mat-error>Insira o título</mat-error>
            </mat-form-field>
            <mat-form-field class="full-container">
                <mat-label>Descrição</mat-label>
                <textarea matInput type="text" formControlName="description" maxlength="500" [rows]="editMode ? 1 : 6"
                    #descriptionInput></textarea>
                <mat-hint align="end">{{ form.value.description?.length ?? 0 }} / 500</mat-hint>
                <mat-error>Insira a descrição</mat-error>
            </mat-form-field>
            <mat-form-field class="full-container" *ngIf="!editMode">
                <mat-label>Destinatário (opcional) <mat-icon *ngIf="form.value.userID">push_pin</mat-icon>
                </mat-label>
                <input #userInput matInput [matAutocomplete]="autoUser" class="search-input"
                    (input)="filterBySearch(userInput.value)" [readonly]="form.value.userID" placeholder="PESQUISAR...">
                <button mat-icon-button matSuffix (click)="applySearchSelection(null);"
                    *ngIf="userInput.value.length > 0" class="btn-remove" matTooltip="Remover">
                    <mat-icon>close</mat-icon>
                </button>
                <mat-autocomplete #autoUser="matAutocomplete" autoActiveFirstOption>
                    <mat-option *ngFor="let user of filteredUsers" (onSelectionChange)="applySearchSelection(user)"
                        [value]="user.name">
                        <small>{{ user.name.toLocaleUpperCase() }}</small>
                    </mat-option>
                </mat-autocomplete>
            </mat-form-field>
            <div *ngIf="isStatic && !editMode">
                <button mat-button color="primary" class="action-button" *ngIf="form.value.acceptanceTerm == null"
                    (click)="selectAcceptanceTerm()">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <mat-icon>find_in_page</mat-icon>
                        <label>Selecionar Termo de Aceite</label>
                    </div>
                </button>
                <button mat-button color="primary" class="action-button" *ngIf="form.value.acceptanceTerm != null"
                    [matMenuTriggerFor]="acceptanceTermMenu">
                    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                        <mat-icon>find_in_page</mat-icon>
                        <label>{{ form.value.acceptanceTerm?.title }}</label>
                    </div>
                </button>
                <mat-menu #acceptanceTermMenu="matMenu">
                    <button mat-menu-item (click)="viewAcceptanceTerm()">
                        <mat-icon>document_scanner</mat-icon>
                        <span>Visualizar</span>
                    </button>
                    <button mat-menu-item (click)="selectAcceptanceTerm()">
                        <mat-icon>sync</mat-icon>
                        <span>Alterar</span>
                    </button>
                    <button mat-menu-item (click)="removeAcceptanceTerm()">
                        <mat-icon>delete</mat-icon>
                        <span>Remover</span>
                    </button>
                </mat-menu>
            </div>
        </div>
        <div class="full-container" fxLayout="column" fxLayoutAlign="none start" *ngIf="!editMode">
            <small class="file-header-label">Arquivos (Opcional)</small>
            <a (click)="dialog.click()" class="full-container">
                <div class="drag-container" DragAndDropDirective (fileDropped)="uploadFile($event)">
                    <mat-icon class="drag-icon">swipe_down</mat-icon>
                    <small class="drag-label">Clique para procurar ou arraste<br>os arquivos</small>
                </div>
            </a>
            <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px"
                class="files-carrousel files-carrousel-edit-mode">
                <div *ngFor="let file of files" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                    (click)="removeFile(file)">
                    <img [src]="file.path | safe: 'resourceUrl'" class="small-image" *ngIf="isFileImage(file.type)">
                    <iframe [src]="file.path | safe: 'resourceUrl'" class="small-iframe"
                        *ngIf="!isFileImage(file.type)"></iframe>
                    <mat-icon class="remove-file-icon">delete</mat-icon>
                </div>
            </div>
            <input hidden type="file" multiple #dialog (change)="uploadFile($event)">
        </div>
    </div>
    <div *ngIf="isInPreviewMode">
        <mat-card class="post">
            <div fxLayout="row" fxLayoutAlign="space-between start" style="margin-left: 10px; margin-top: 10px;">
                <div fxLayout="column" fxLayoutAlign="center start">
                    <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="15px">
                        <mat-card-title>{{ form.value.title?.trim().length > 0 ? form.value.title : 'Título da
                            Publicação' }}</mat-card-title>
                        <span class="bg-label other mat-small" style="line-height: 1;"
                            *ngIf="form.value.userID != null">
                            DESTINATÁRIO DEFINIDO
                        </span>
                    </div>
                    <span class="post-info">
                        <small class="grey">Publicado em {{ currentDateTime() | date:"dd/MM/yyyy HH:mm" }}</small>
                    </span>
                    <mat-card-subtitle [innerHTML]="getPostDescription()"></mat-card-subtitle>
                </div>
            </div>
            <div class="post-content" *ngIf="files?.length > 0">
                <app-frame-file class="frame" [filePath]="files[carrouselCurrentIndex]?.path"
                    [fileType]="files[carrouselCurrentIndex]?.type" [allowHoverAction]="false"></app-frame-file>
                <div fxLayout="row" fxLayoutAlign="start center" fxLayoutGap="5px" class="files-carrousel">
                    <a *ngFor="let file of files" fxLayout="column" fxLayoutAlign="center center" fxLayoutGap="4px"
                        class="carrousel-item" (click)="selectCarrouselFile(file)">
                        <img [src]="file.path | safe: 'resourceUrl'" class="small-image"
                            [ngClass]="{'carrousel-current-selection': carrouselCurrentIndex == files.indexOf(file) }"
                            *ngIf="isFileImage(file.type)">
                        <iframe [src]="file.path | safe: 'resourceUrl'" class="small-iframe"
                            *ngIf="!isFileImage(file.type)"></iframe>
                    </a>
                </div>
            </div>
            <button mat-button color="primary" (click)="viewAcceptanceTerm()" *ngIf="form.value.acceptanceTerm != null">
                <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                    <mat-icon>receipt_long</mat-icon>
                    <label>Visualizar o termo</label>
                </div>
            </button>
        </mat-card>
    </div>
</div>
<mat-dialog-actions>
    <button mat-button (click)="cancel()" *ngIf="!isInPreviewMode">Cancelar</button>
    <button mat-raised-button color="primary" (click)="changePreviewMode(true)" *ngIf="!editMode && !isInPreviewMode"
        [disabled]="form.invalid">Pré-visualizar</button>
    <button mat-button color="primary" (click)="changePreviewMode(false)" *ngIf="!editMode && isInPreviewMode">Continuar
        editando</button>
    <button mat-raised-button color="primary" *ngIf="isInPreviewMode || editMode" (click)="save()">Publicar</button>
</mat-dialog-actions>