import moment from "moment";
import { Authentication } from "../models/authentication";
import { User } from "../models/user";
import { Permissions, Role } from "../models/role";
import * as fileSaver from 'file-saver';

export module Extensions {
    export function isUserInRole(user: Authentication, role: string): boolean {
        return user?.roles.find(x => x === role) != null;
    }

    export function isUserInAnyRole(user: Authentication | User, roles: string[]): boolean {
        return user?.roles.some(r => roles.includes(r));
    }

    export function groupBy<T, K>(list: T[], getKey: (item: T) => K) {
        const map = new Map<K, T[]>();
        list.forEach((item) => {
            const key = getKey(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            }
            else {
                collection.push(item);
            }
        });
        return Array.from(map.values());
    }

    export function undoGroupBy<T>(list: T[][]) {
        const collection = [];
        list.forEach((group) => {
            group.forEach((item) => {
                collection.push(item);
            })
        });
        return collection;
    }

    export function formatDate(date: any, isEndDate?) {
        return isEndDate ? moment(date).format('YYYY-MM-DDT23:59:59') : moment(date).format('YYYY-MM-DDT00:00:00');
    }

    export function convertImage(image) {
        if (!image.includes('data:image/jpeg;base64,')) {
            return `data:image/jpeg;base64,${image}`;
        }

        return image;
    }

    export function isDateBetween(d1: Date, d2: Date) {
        return (new Date(d1) >= new Date(d2) && new Date(d1) <= new Date(d2));
    }

    export function distinct<T, K>(list: T[], getKey: (item: T) => K) {
        return list.map(getKey).filter((value, index, self) => self.indexOf(value) === index && ![null, undefined].includes(value));
    }

    export function distinctBy<T, K>(list: T[], getKey: (item: T) => K) {
        const uniqueKeys = new Set<K>();
        const result: T[] = [];

        for (const item of list) {
            const key = getKey(item);

            if (!uniqueKeys.has(key)) {
                uniqueKeys.add(key);
                result.push(item);
            }
        }

        return result;
    }

    export function formatMoney(value) {
        return Math.abs(Number(value)) >= 1.0e+9

            ? `${Math.round(Math.abs(Number(value)) / 1.0e+9)}B`
            : Math.abs(Number(value)) >= 1.0e+6

                ? `${Math.round(Math.abs(Number(value)) / 1.0e+6)}M`
                : Math.abs(Number(value)) >= 1.0e+3

                    ? `${Math.round(Math.abs(Number(value)) / 1.0e+3)}K`

                    : Math.round(Math.abs(Number(value))).toString();

    }

    export function formatStorage(bytes) {
        var sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
        var i = Math.floor(Math.log(bytes) / Math.log(1024));
        return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + ' ' + sizes[i];
    }

    export function getPermissionsForRole(role: string) {
        let assignedPermissions: string[] = [];
        let permissions = Object.keys(Permissions).map(p => Permissions[p]).filter(p => typeof p == "number");

        permissions.forEach(p => {
            let allowedRoles = Role.getAllowedRoles(p as Permissions);

            if (allowedRoles.map(r => r.toString()).includes(role)) {
                assignedPermissions.push(Permissions[p]);
            }
        });

        return assignedPermissions;
    }

    export function saveAsExcelFile(buffer: any, fileName: string): void {
        const EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        const EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], { type: EXCEL_TYPE });
        fileSaver.saveAs(data, `${fileName}_${new Date().getTime()}${EXCEL_EXTENSION}`);
    }
}