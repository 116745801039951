import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Inspection } from "../models/inspection";
import { environment } from "src/environments/environment";
import { InspectionItem } from "../models/inspection-item";
import { InspectionCriteria } from "../models/inspection-criteria";
import { RiskRating } from "../models/risk-rating";
import { RiskRatingItem } from "../models/risk-rating-item";
import { RiskRatingCriteria } from "../models/risk-rating-criteria";
import { TermsOfUse } from "../models/terms-of-use";
import { AcceptanceTerm } from "../models/acceptance-term";

@Injectable({
    providedIn: 'root'
})

export class ComplianceService {
    constructor(private http: HttpClient) { }

    getInspections(params) {
        return this.http.get<Inspection[]>(`${environment.API}inspections`, { params }).pipe();
    }

    getInspectionItems(params) {
        return this.http.get<InspectionItem[]>(`${environment.API}inspections/items`, { params }).pipe();
    }

    getInspectionsReport(params?) {
        return this.http.get<Inspection[]>(`${environment.API}inspections/report`, { params }).pipe();
    }

    getInspectionCriteria(params?) {
        return this.http.get<InspectionCriteria[]>(`${environment.API}inspections/criteria`, { params }).pipe();
    }

    createInspectionCriteria(body) {
        return this.http.post<any>(`${environment.API}inspections/criteria`, body).pipe();
    }

    updateInspectionCriteria(ID: number, body) {
        return this.http.put<any>(`${environment.API}inspections/criteria/${ID}`, body).pipe();
    }

    deleteInspectionCriteria(params) {
        return this.http.delete<any>(`${environment.API}inspections/criteria`, { params }).pipe();
    }

    getAcceptanceTerms() {
        return this.http.get<AcceptanceTerm[]>(`${environment.API}acceptanceTerms`).pipe();
    }

    createAcceptanceTerm(body) {
        return this.http.post<any>(`${environment.API}acceptanceTerms`, body).pipe();
    }

    updateAcceptanceTerm(acceptanceTermID: number, body) {
        return this.http.put<any>(`${environment.API}acceptanceTerms/${acceptanceTermID}`, body).pipe();
    }

    deleteAcceptanceTerm(params) {
        return this.http.delete<any>(`${environment.API}acceptanceTerms`, { params }).pipe();
    }

    getRiskRatings(params) {
        return this.http.get<RiskRating[]>(`${environment.API}riskRatings`, { params }).pipe();
    }

    getRiskRatingItems(params) {
        return this.http.get<RiskRatingItem[]>(`${environment.API}riskRatings/items`, { params }).pipe();
    }

    getRiskRatingsReport(params?) {
        return this.http.get<RiskRating[]>(`${environment.API}riskRatings/report`, { params }).pipe();
    }

    createRiskRating(body) {
        return this.http.post<any>(`${environment.API}riskRatings`, body).pipe();
    }

    getRiskRatingCriteria(params?) {
        return this.http.get<RiskRatingCriteria[]>(`${environment.API}riskRatings/criteria`, { params }).pipe();
    }

    createRiskRatingCriteria(body) {
        return this.http.post<any>(`${environment.API}riskRatings/criteria`, body).pipe();
    }

    updateRiskRatingCriteria(ID: number, body) {
        return this.http.put<any>(`${environment.API}riskRatings/criteria/${ID}`, body).pipe();
    }

    deleteRiskRatingCriteria(params) {
        return this.http.delete<any>(`${environment.API}riskRatings/criteria`, { params }).pipe();
    }

    getTermsOfUse(params?) {
        return this.http.get<TermsOfUse[]>(`${environment.API}termsOfUse`, { params }).pipe();
    }

    createTermsOfUse(body) {
        return this.http.post<any>(`${environment.API}termsOfUse`, body).pipe();
    }

    updateTermsOfUse(ID: number, body) {
        return this.http.put<any>(`${environment.API}termsOfUse/${ID}`, body).pipe();
    }

    deleteTermsOfUse(params) {
        return this.http.delete<any>(`${environment.API}termsOfUse`, { params }).pipe();
    }

    toggleTermsOfUse(body) {
        return this.http.put<any>(`${environment.API}termsOfUse/toggle`, body).pipe();
    }

    getTermsOfUseVersions(params) {
        return this.http.get<TermsOfUse[]>(`${environment.API}termsOfUse/versions`, { params }).pipe();
    }

    acceptTermsOfUse(body) {
        return this.http.post<any>(`${environment.API}termsOfUse/accept`, body).pipe();
    }
}