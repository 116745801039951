import { Component, OnInit, Inject } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MustMatchValidator } from 'src/app/helpers/must-match.validator';
import { User } from 'src/app/models/user';
import { NotificationService } from 'src/app/services/notification.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  user: User;
  form: UntypedFormGroup;
  panelOpenState = false;
  strength = 0;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private userService: UserService, private notificationService: NotificationService) { }

  ngOnInit() {
    this.user = this.data.user;

    this.form = new UntypedFormGroup({
      password: new UntypedFormControl('', [Validators.required, Validators.minLength(8)]),
      confirmPassword: new UntypedFormControl('', [Validators.required])
    }, { validators: MustMatchValidator("password", "confirmPassword") });
  }

  save() {
    if (this.form.invalid) {
      this.notificationService.error("Senha inválida.");
      return;
    }

    this.userService.changePassword(this.user.id, this.form.value.password, this.form.value.confirmPassword).subscribe({
      next: () => {
        this.dialogRef.close(true);
      },
      error: error => {
        this.notificationService.error(error);
      }
    });
  }

  verifyStrength() {
    let upperCase = ('ABCDEFGHIJKLMNOPQRSTUVWXYZ').split('');
    let numeric = ('1234567890').split('');
    let special = ('\.[]{}()<>*+-=!?^$|@#%/|').split('');
    let password = this.form.value.password;
    let lengthStrength = (password?.length / 2) * 10;
    this.strength = 0;

    if (password?.length <= 8) {
      this.strength += lengthStrength - 2;
    }
    else {
      this.strength = (password?.length / 2) * 10;
    }

    if (upperCase.some(i => password.indexOf(i) >= 0)) {
      this.strength += lengthStrength < 10 ? lengthStrength : 10;
    }

    if (numeric.some(i => password.indexOf(i) >= 0)) {
      this.strength += lengthStrength < 10 ? lengthStrength : 10;
    }

    if (special.some(i => password.indexOf(i) >= 0)) {
      this.strength += lengthStrength < 10 ? lengthStrength : 10;
    }

    if (this.strength > 0 && password?.length > 1) {
      let chars = password.split('');
      let lowerCaseChars = upperCase.map(i => i.toLocaleLowerCase());
      let upperCaseChars = upperCase.map(i => i);

      for (let i = 0; i < chars.length; i++) {
        // Numbers in sequence (1, 2, 3...)
        let numbersSequence = (Number.parseInt(chars[i]) + 1) == chars[i + 1] || (Number.parseInt(chars[i]) + 1) == chars[i - 1];
        // Lower chars in sequence (a, b, c...)
        let lowerCaseCharsSequence = lowerCaseChars.indexOf(chars[i + 1]) == (lowerCaseChars.indexOf(chars[i]) + 1);
        // Upper chars in sequence (A, B, C...)
        let upperCaseCharsSequence = (upperCaseChars.indexOf(chars[i + 1])) == (upperCaseChars.indexOf(chars[i]) + 1);

        // Same chars in sequence (a, a, 1, 1, @, @...)
        if (chars[i] == chars[i + 1] || numbersSequence || lowerCaseCharsSequence || upperCaseCharsSequence) {
          this.strength -= 5;
        }
      }
    }
  }
}
