import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { LoginComponent } from './public/login/login.component';
import { HomeComponent } from './home/home.component';
import { HomeLayoutComponent } from './layouts/home-layout.component';
import { LoginLayoutComponent } from './layouts/login-layout.component';
import { SendCodeComponent } from './public/send-code/send-code.component';
import { ResetPasswordComponent } from './public/reset-password/reset-password.component';
import { VerifyCodeComponent } from './public/verify-code/verify-code.component';
import { Permissions, Role } from './models/role';
import { MobileWarningComponent } from './public/mobile-warning/mobile-warning.component';
import { TwoFactorAuthenticationComponent } from './public/two-factor-authentication/two-factor-authentication.component';


const routes: Routes = [
  {
    path: '',
    component: HomeLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        component: HomeComponent
      },
      {
        path: 'admin',
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
        canActivate: [AuthGuard], data: { roles: Role.getAllowedRoles(Permissions.ViewAdminTabs) }
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(m => m.ReportsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'messaging',
        loadChildren: () => import('./messaging/messaging.module').then(m => m.MessagingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'properties',
        loadChildren: () => import('./properties/properties.module').then(m => m.PropertiesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'entries',
        loadChildren: () => import('./entries/entries.module').then(m => m.EntriesModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'documents',
        loadChildren: () => import('./documents/documents.module').then(m => m.DocumentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'compliance',
        loadChildren: () => import('./compliance/compliance.module').then(m => m.ComplianceModule),
        canActivate: [AuthGuard]
      }
    ]
  },
  {
    path: '',
    component: LoginLayoutComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'send-code',
        component: SendCodeComponent
      },
      {
        path: 'verify-code',
        component: VerifyCodeComponent
      },
      {
        path: 'reset-password',
        component: ResetPasswordComponent
      },
      {
        path: 'mobile',
        component: MobileWarningComponent
      },
      {
        path: 'two-factor-authentication',
        component: TwoFactorAuthenticationComponent
      }
    ]
  },
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }