<h1 mat-dialog-title>Nova Enquete</h1>
<mat-dialog-content [formGroup]="form">
    <mat-form-field class="full-container">
        <mat-label>Título</mat-label>
        <input matInput type="text" formControlName="title" maxlength="100">
        <mat-hint align="end">{{ form.value.title?.length ?? 0 }} / 100</mat-hint>
        <mat-error>Insira o título</mat-error>
    </mat-form-field>
    <div fxLayout="row" fxLayoutAlign="center baseline" fxLayoutGap="8px">
        <mat-form-field class="half-container">
            <mat-label>Disponível até</mat-label>
            <input matInput [matDatepicker]="picker" matInput formControlName="dueDate" [min]="minDate">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error>Insira a data</mat-error>
        </mat-form-field>
        <mat-form-field class="half-container">
            <mat-label>Hora</mat-label>
            <input matInput type="time" formControlName="dueTime" step="300">
        </mat-form-field>
    </div>
    <mat-slide-toggle formControlName="allowRevoting" color="primary" style="padding: 0px 0px 25px 10px;">Permitir
        alteração de voto</mat-slide-toggle>
    <div fxLayout="row" fxLayoutAlign="space-between center">
        <button mat-button color="primary" (click)="addOption()">Adicionar opção</button>
        <small>Opções: {{ options?.length }} / {{ maxOptionsPerPoll }}</small>
    </div>
    <br>
    <mat-radio-group fxLayout="column" fxLayoutAlign="start start">
        <div *ngFor="let option of options" class="poll-option">
            <mat-radio-button [value]="options.indexOf(option)" class="readonly" tabindex="-1"></mat-radio-button>
            <mat-form-field>
                <input matInput type="text" maxlength="40" [value]="option"
                    (blur)="updateOption(options.indexOf(option), optionInput.value)" #optionInput>
            </mat-form-field>
            <button mat-icon-button class="poll-option-delete-button" (click)="removeOption(options.indexOf(option))">
                <mat-icon>delete</mat-icon>
            </button>
        </div>
    </mat-radio-group>
</mat-dialog-content>
<mat-dialog-actions>
    <button mat-button (click)="close()">Cancelar</button>
    <button mat-raised-button color="primary" (click)="save()">Ok</button>
</mat-dialog-actions>