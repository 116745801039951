<div fxLayout="column" fxLayoutAlign="center start" *ngIf="showExportButton">
    <button mat-icon-button class="absolute-position" printSectionId="canvas" [useExistingCss]="true" ngxPrint>
        <mat-icon class="material-icons-outlined">file_download</mat-icon>
    </button>
</div>
<div id="canvas">
    <mat-dialog-content class="dialog-content">
        <quill-view-html class="full-container" [content]="acceptanceTerm.description"
            [ngStyle]="{'padding-bottom: 90px': showAcceptButton}"></quill-view-html>            
    <div *ngIf="signedAcceptanceTerm != null">
        <mat-card class="confirmation-card mat-elevation-z4">
            <mat-icon class="success-text badge">verified</mat-icon>
            <label mat-dialog-title>Confirmação do Aceite</label>
            <div fxLayout="row" fxLayoutAlign="start start" fxLayoutGap="10px">
                <mat-card-content>Este termo foi aceito digitalmente em {{ signedAcceptanceTerm.acceptedAt | date:"dd/MM/yyyy" }} às {{
                    signedAcceptanceTerm.acceptedAt | date:"HH:mm" }} (UTC), pelo usuário {{ signedAcceptanceTerm.associate.name
                    }},
                    registrado pelo endereço de IP {{ signedAcceptanceTerm.ipAddress }}
                </mat-card-content>
            </div>
        </mat-card>
        <br>
    </div>
    </mat-dialog-content>
    <div *ngIf="showAcceptButton" class="absolute-position middle">
        <button mat-raised-button color="primary" (click)="acceptTerm()">
            <div fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="5px">
                <label>Eu aceito o termo</label>
            </div>
        </button>
    </div>
</div>