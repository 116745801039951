<mat-dialog-content>
    <h1 mat-dialog-title>Configuração do autenticador</h1>
    <div>
        Como proceder?<br>
        <ul>
            <li>
                <small>Baixe o app
                    <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank">Google
                        Authenticator</a>
                    na Google Play Store.
                </small>
            </li>
            <li>
                <small>Entre com a sua conta do e-mail (opcional).</small>
            </li>
            <li>
                <small>Realize a configuração do autenticador utilizando um dos métodos abaixo.</small>
            </li>
            <br>
            <small>Ainda tem alguma dúvida? 
                <a href="https://support.google.com/accounts/answer/1066447?hl=pt&amp;co=GENIE.Platform%3DAndroid&amp;oco=1" target="_blank">Veja mais infomações</a>.
            </small>
        </ul>
    </div>
    <mat-divider></mat-divider>
    <br>
    <div fxLayout="column" fxLayoutAlign="center center">
        <small>Insira a chave de configuração manual</small>
        <br>
        <h1 mat-dialog-title class="break-on-overflow">{{ twoFactorAuthenticator.manualEntryKey }}</h1>
        <label>Ou</label><br>
    </div>
    <div fxLayout="column" fxLayoutAlign="center center">
        <small>Escaneie o QR code</small>
        <img width="200" height="200" [src]="twoFactorAuthenticator.qrCodeImageUrl">
    </div>
</mat-dialog-content>
<mat-dialog-actions fxLayout="row" fxLayoutAlign="center center" class="actions-container">
    <button mat-button color="primary" (click)="close()">Feito!</button>
</mat-dialog-actions>