import { EChartsOption } from "echarts/lib/echarts";
import { AcceptanceTerm } from "./acceptance-term";
import { Attachment } from "../view-models/attachment";

export class Post {
    id: number;
    postDate: Date;
    edited: Date;
    title: string;
    description: string;
    attachments: Attachment[];
    selectedAttachmentIndex: number;
    allowEdition: boolean;
    createdBy?: string;
    unread: boolean;
    isLiked: boolean;
    likedByCount: number;
    hasTargetUser: boolean;
    readByCount: number;
    pendingReadingCount: number;
    acceptanceTermAcceptedByCount: number;
    pendingAcceptanceCount: number;
    acceptanceTermAccepted: boolean;
    acceptanceTerm?: AcceptanceTerm;
    chart: EChartsOption;

    constructor(post: Post) {
        for (const key in post) {
            if (post.hasOwnProperty(key)) {
                this[key] = post[key];
            }
        }
    }
}