import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Notification, NotificationType } from "../view-models/notification";
import Translations from "src/assets/json/translations.json";
import { HttpClient } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { PushNotification } from "../models/push-notification";

@Injectable({ providedIn: 'root' })
export class NotificationService {
    private notification$: Subject<Notification> = new Subject();
    private defaultId = 'default-alert';
    public notificationCountSubject: BehaviorSubject<number>;
    public notificationCount: Observable<number>;

    constructor(private http: HttpClient) {
        this.notificationCountSubject = new BehaviorSubject<number>(null);
        this.notificationCount = this.notificationCountSubject.asObservable();
    }

    // enable subscribing to notifications observable
    onNotify(id = this.defaultId): Observable<Notification> {
        return this.notification$.asObservable().pipe(filter(x => x && x.id === id));
    }

    sucess(message: string) {
        this.add(message, "OK", { autoClose: true, type: NotificationType.Success });
    }

    error(message: string) {
        if (!message.endsWith('.')) {
            message = `${message}.`;
        }

        this.add(Translations.Errors[message] ?? message, "OK", { autoClose: false, type: NotificationType.Error });
    }

    warning(message: string) {
        this.add(message, "OK", { autoClose: false, type: NotificationType.Warning });
    }

    info(message: string) {
        this.add(message, "OK", { autoClose: false, type: NotificationType.Info });
    }

    forbid() {
        this.add('Você não possui permissão para este recurso.', "OK", { autoClose: false, type: NotificationType.Forbid })
    }

    messagePopUp(title: string, message: string, params: any) {
        this.addMessagePopUp(title, message, params, { autoClose: false, type: NotificationType.MessagePopUp });
    }

    private add(message: string, action: string, options?: any) {
        this.notify(new Notification({ ...options, message, action }));
    }

    private addMessagePopUp(title: string, message: string, params: any, options?: any) {
        this.notify(new Notification({ ...options, title, message, params }));
    }

    private notify(notification: Notification) {
        notification.id = notification.id || this.defaultId;
        notification.autoClose = (notification.autoClose === undefined ? true : notification.autoClose);
        notification.type = (notification.type === undefined ? NotificationType.Success : notification.type);
        this.notification$.next(notification);
    }

    getNotifications(params) {
        return this.http.get<PushNotification[]>(`${environment.API}notifications`, { params }).pipe();
    }

    readNotifications() {
        return this.http.put<any>(`${environment.API}notifications`, { }).pipe();
    }
}