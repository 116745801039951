import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { NotificationService } from 'src/app/services/notification.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-recaptcha-dialog',
    templateUrl: './recaptcha-dialog.component.html',
    styleUrls: ['./recaptcha-dialog.component.scss']
})

export class RecaptchaDialogComponent implements OnInit {
    siteKey: string = environment.siteKey;
    form: UntypedFormGroup;

    constructor(private dialogRef: MatDialogRef<RecaptchaDialogComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            reCaptcha: new UntypedFormControl(null, [Validators.required])
        });
    }

    onSubmit() {
        if (this.form.invalid) {
            this.notificationService.warning('Complete o reCaptcha');
            return;
        }
        
        this.dialogRef.close(this.form.value.reCaptcha);
    }
}
