export class SidenavItem {
    title: string;
    icon: string;
    route: string;
    adminsOnly: boolean;

    constructor (title: string, icon: string, route: string, adminsOnly: boolean = false) {
        this.title = title;
        this.icon = icon;
        this.route = route;
        this.adminsOnly = adminsOnly;
    }
}