import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Associate } from '../models/associate';
import { ViticultureRegistration } from '../models/viticulture-registration';
import { DAP } from '../models/dap';
import { Item } from '../models/item';
import { AssociateGroup } from '../models/associate-group';
import { ItemBrand } from '../models/item-brand';
import { ChemicalClass } from '../models/chemical-class';
import { ActiveIngredient } from '../models/active-ingredient';
import { DetailedItem } from '../models/detailed-item';
import { Classification } from '../models/classification';
import { PriceList } from '../models/price-list';
import { ItemPrice } from '../models/item-price';
import { Package } from '../models/package';
import { Harvest } from '../models/harvest';
import { AssociateAuthorization } from '../models/associate-authorization';

@Injectable({
  providedIn: 'root'
})

export class EntryService {
  constructor(private http: HttpClient) { }

  getAssociates() {
    return this.http.get<Associate[]>(`${environment.API}associates`).pipe();
  }

  getAssociate(params?) {
    return this.http.get<Associate>(`${environment.API}associates/user`, { params }).pipe();
  }

  getAssignedAssociates(params) {
    return this.http.get<Associate[]>(`${environment.API}associates/assigned-associates`, { params }).pipe();
  }

  getAssociateAuthorizations(params) {
    return this.http.get<AssociateAuthorization[]>(`${environment.API}associates/authorizations`, { params }).pipe();
  }

  authorizeAssociate(body) {
    return this.http.post<any>(`${environment.API}associates/authorizations/authorize`, body).pipe();
  }

  revokeAssociateAuthorization(body) {
    return this.http.post<any>(`${environment.API}associates/authorizations/revoke`, body).pipe();
  }

  updateAssociateProfile(body) {
    return this.http.put<any>(`${environment.API}associates/update-profile`, body).pipe();
  }

  getViticultureRegistrations(params?) {
    return this.http.get<ViticultureRegistration[]>(`${environment.API}associates/viticulture-registrations`, { params }).pipe();
  }

  getViticultureRegistrationsByGroup(params?) {
    return this.http.get<ViticultureRegistration[]>(`${environment.API}associates/groups/viticulture-registrations`, { params }).pipe();
  }

  createViticultureRegistration(body) {
    return this.http.post<any>(`${environment.API}associates/viticulture-registrations`, body).pipe();
  }

  updateViticultureRegistration(ViticultureRegistrationID: number, body) {
    return this.http.put<ViticultureRegistration[]>(`${environment.API}associates/viticulture-registrations/${ViticultureRegistrationID}`, body).pipe();
  }

  deleteViticultureRegistration(params) {
    return this.http.delete<any>(`${environment.API}associates/viticulture-registrations`, { params }).pipe();
  }

  getItems(params?) {
    return this.http.get<Item[]>(`${environment.API}items`, { params }).pipe();
  }

  getDetailedItems(params?) {
    return this.http.get<DetailedItem[]>(`${environment.API}items/detailed`, { params }).pipe();
  }

  getItemByID(params) {
    return this.http.get<DetailedItem>(`${environment.API}items/id`, { params }).pipe();
  }

  updateItem(itemID: number, body) {
    return this.http.put<any>(`${environment.API}items/${itemID}`, body).pipe();
  }

  getItemBrands() {
    return this.http.get<ItemBrand[]>(`${environment.API}items/brands`).pipe();
  }

  getItemPackages(params) {
    return this.http.get<Package[]>(`${environment.API}items/packages`, { params }).pipe();
  }

  getChemicalClasses() {
    return this.http.get<ChemicalClass[]>(`${environment.API}items/chemical-classes`).pipe();
  }

  getActiveIngredients() {
    return this.http.get<ActiveIngredient[]>(`${environment.API}items/active-ingredients`).pipe();
  }

  getClassifications() {
    return this.http.get<Classification[]>(`${environment.API}items/classifications`).pipe();
  }

  getPriceLists(params?) {
    return this.http.get<PriceList[]>(`${environment.API}priceLists`, { params }).pipe();
  }

  getPriceListByID(params?) {
    return this.http.get<PriceList>(`${environment.API}priceLists/id`, { params }).pipe();
  }

  createPriceList(body) {
    return this.http.post<any>(`${environment.API}priceLists`, body).pipe();
  }

  updatePriceList(priceListID: number, body) {
    return this.http.put<any>(`${environment.API}priceLists/${priceListID}`, body).pipe();
  }

  deletePriceList(params) {
    return this.http.delete<any>(`${environment.API}priceLists`, { params }).pipe();
  }

  getItemPrices(params) {
    return this.http.get<ItemPrice[]>(`${environment.API}priceLists/item-prices`, { params }).pipe();
  }

  createItemPrice(body) {
    return this.http.post<any>(`${environment.API}priceLists/item-prices`, body).pipe();
  }

  importItemPrices(body) {
    return this.http.post<any>(`${environment.API}priceLists/item-prices/import`, body).pipe();
  }

  updateItemPrice(priceListID: number, body) {
    return this.http.put<any>(`${environment.API}priceLists/item-prices/${priceListID}`, body).pipe();
  }

  deleteItemPrice(params) {
    return this.http.delete<any>(`${environment.API}priceLists/item-prices`, { params }).pipe();
  }

  getDAP(params?) {
    return this.http.get<DAP>(`${environment.API}associates/dap`, { params }).pipe();
  }

  getAssociateGroups(params?) {
    return this.http.get<AssociateGroup[]>(`${environment.API}associates/groups`, { params }).pipe();
  }

  createAssociateGroup(body) {
    return this.http.post<any>(`${environment.API}associates/groups`, body).pipe();
  }

  updateAssociateGroup(groupID: number, body) {
    return this.http.put<any>(`${environment.API}associates/groups/${groupID}`, body).pipe();
  }

  deleteAssociateGroup(params) {
    return this.http.delete<any>(`${environment.API}associates/groups`, { params }).pipe();
  }

  getHarvests(params?) {
    return this.http.get<Harvest[]>(`${environment.API}harvests`, { params }).pipe();
  }

  createHarvest(body) {
    return this.http.post<any>(`${environment.API}harvests`, body).pipe();
  }

  updateHarvest(harvestID: number, body) {
    return this.http.put<any>(`${environment.API}harvests/${harvestID}`, body).pipe();
  }

  deleteHarvest(params) {
    return this.http.delete<any>(`${environment.API}harvests`, { params }).pipe();
  }
}
