import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Authentication } from '../models/authentication';
import { UserService } from '../services/user.service';
import { NotificationService } from '../services/notification.service';
import { User } from '../models/user';
import { Extensions } from '../helpers/extensions';
import { SidenavItem } from '../view-models/sidenav-item';
import { Subscription, forkJoin } from 'rxjs';
import { SignalrService } from '../services/signalr.service';
import { Encryptions } from '../helpers/encryptions';
import { MessageService } from '../services/message.service';
import { SidenavParent } from '../view-models/sidenav-parent';
import { ViewTermsOfUseComponent } from '../compliance/terms-of-use/view-terms-of-use/view-terms-of-use.component';
import { ComplianceService } from '../services/compliance.service';
import { MatDialog } from '@angular/material/dialog';
import { Permissions, Role } from '../models/role';
import { UpdateProfileComponent } from '../settings/update-profile/update-profile.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  loggedUser: User;
  authUser: Authentication;
  notificationSubscription: Subscription;
  messageSubscription: Subscription;
  notificationCount: number = 0;
  allowSidenavAccess: boolean;
  allowAdminTabsAccess: boolean;
  allowMessagesAccess: boolean;

  sidenavParents: SidenavParent[] = [
    new SidenavParent('Administração', [
      new SidenavItem('Usuários', 'groups_2', '/admin/users', true),
      new SidenavItem('Papéis', 'badge', '/admin/roles', true),
      new SidenavItem('Cooperados', 'family_restroom', '/admin/associates', true),
      new SidenavItem('Famílias', 'diversity_3', '/admin/associate-groups', true),
      new SidenavItem('Jobs', 'event_repeat', '/admin/jobs', true),
      new SidenavItem('Parâmetros', 'tune', '/admin/parameters', true),
      new SidenavItem('Versões do App', 'widgets', '/admin/app-versions', true),
    ]),
    new SidenavParent('Cadastros', [
      new SidenavItem('Itens', 'category', '/entries/items'),
      new SidenavItem('Safras', 'front_loader', '/entries/harvests'),
      new SidenavItem('Códigos Vitícola', 'document_scanner', '/entries/viticulture-registrations'),
      new SidenavItem('Tabelas de Preço', 'point_of_sale', '/entries/price-lists', true),
    ]),
    new SidenavParent('Propriedades', [
      new SidenavItem('Áreas', 'forest', '/properties/areas'),
      new SidenavItem('Produção', 'agriculture', '/properties/production'),
      new SidenavItem('Maquinário', 'precision_manufacturing', '/properties/machinery'),
      new SidenavItem('Checklists', 'playlist_add_check', '/properties/checklists'),
      new SidenavItem('Regulagens', 'plumbing', '/properties/pulverizers/setups'),
      new SidenavItem('Pedidos', 'shopping_cart', '/properties/orders'),
      new SidenavItem('Visitas Técnicas', 'hail', '/properties/technical-visits'),
    ]),
    new SidenavParent('Relatórios', [
      new SidenavItem('Notas de Safra', 'newspaper_outlined', '/reports/invoices'),
      new SidenavItem('Relatório de Safra', 'equalizer', '/reports/harvest-report'),
      new SidenavItem('Financeiro', 'attach_money', '/reports/financial-transactions'),
    ]),
    new SidenavParent('Documentos', [
      new SidenavItem('Publicações Legais', 'history_edu', '/documents/legal-posts'),
      new SidenavItem('DAP', 'folder_shared', '/documents/dap'),
    ]),
    new SidenavParent('Compliance', [
      new SidenavItem('Termos de Aceite', 'edit_document', '/compliance/acceptance-terms', true),
      new SidenavItem('Termos de Uso', 'verified_user', '/compliance/terms-of-use', true),
      new SidenavItem('Vistorias', 'policy', '/compliance/inspections'),
      new SidenavItem('Avaliações de Risco', 'flag', '/compliance/risk-ratings', true),
    ]),
  ];

  @ViewChild('sidenav') sidenav;

  constructor(private userService: UserService, private router: Router, private notificationService: NotificationService,
    private signalRService: SignalrService, private messageService: MessageService, private complianceService: ComplianceService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.notificationSubscription = this.notificationService.notificationCount.subscribe(count => {
      this.notificationCount = count;
    });

    this.userService.authentication.subscribe({
      next: authentication => {
        this.authUser = authentication;
        
        if (this.authUser != null) {
          this.allowSidenavAccess = this.authUser.isAssociate || Extensions.isUserInAnyRole(this.authUser, Role.getAllowedRoles(Permissions.ViewSidenavMenu));
          this.allowAdminTabsAccess = !this.authUser.isAssociate && Extensions.isUserInAnyRole(this.authUser, Role.getAllowedRoles(Permissions.ViewAdminTabs));
          this.allowMessagesAccess = this.authUser.isAssociate || Extensions.isUserInAnyRole(this.authUser, Role.getAllowedRoles(Permissions.AccessMessages));
          this.getUserImage(this.authUser);

          this.sidenavParents = this.sidenavParents.filter(s => !s.adminsOnlyTab || this.allowAdminTabsAccess);
        }
      },
      error: error => {
        this.notificationService.error(error);
      }
    });

    const notificationsRequest = this.notificationService.getNotifications({ unreadOnly: true });
    const termsOfUseRequest = this.complianceService.getTermsOfUse({ activeOnly: true });

    let requests = this.authUser.isAssociate ? [notificationsRequest, termsOfUseRequest] : [notificationsRequest];

    forkJoin(requests).subscribe({
      next: data => {
        this.notificationService.notificationCountSubject.next(data[0]?.length);

        if (data[1] != null && data[1].length > 0) {
          const dialogRef = this.dialog.open(ViewTermsOfUseComponent, { panelClass: 'dialog-extra-large', disableClose: true, data: { termsOfUse: data[1] } });

          dialogRef.afterClosed().subscribe({
            next: (termsOfUse: number[]) => {
              if (termsOfUse?.length > 0) {
                this.complianceService.acceptTermsOfUse({ acceptedTermsOfUse: termsOfUse }).subscribe({
                  error: error => {
                    this.notificationService.error(error);
                  }
                });
              }
            }
          });
        }
      },
      error: error => {
        this.notificationService.error(error);
      }
    });

    this.messageSubscription = this.signalRService.messageSubscription.subscribe({
      next: subscription => {
        if (subscription) {
          if (this.loggedUser.id == subscription.recipientID) {
            this.signalRService.getHubConnection.send('PongMessageReceived', this.loggedUser.id);

            if (this.router.url != '/messaging') {
              this.messageService.getChats().subscribe({
                next: chats => {
                  var chat = chats.find(c => c.participantID == subscription.senderID);

                  if (chat && !chat.isLastMessageDeleted) {
                    this.notificationService.messagePopUp(chat.participant,
                      Encryptions.decryptAES(chat.lastMessage, chat.isLastSender ? this.loggedUser.id : chat.participantID).content,
                      { participantID: subscription.senderID });
                  }
                },
                error: error => {
                  this.notificationService.error(error);
                }
              });
            }
          }
        }
      }
    });

    this.signalRService.startConnection();
    this.signalRService.addEventListener();
  }

  ngOnDestroy() {
    this.messageSubscription.unsubscribe();
    this.notificationSubscription.unsubscribe();
    this.signalRService.endConnection();
  }

  getUserImage(authUser?: Authentication) {
    if (authUser == null) {
      return;
    }

    this.userService.getUserByID({ ID: authUser?.userID }).subscribe({
      next: user => {
        this.loggedUser = user;
        this.sidenavParents.forEach(p => p.items = p.items.filter(p => !p.adminsOnly || this.allowAdminTabsAccess))
        this.loggedUser.image = user.image?.length > 0 ? Extensions.convertImage(user.image) : null;

        let item = localStorage.getItem('requested-for-2fa-activation');
        let requestedFor2faActivation = item != null && item == 'Y';

        if (!this.loggedUser.is2FAEnabled && !requestedFor2faActivation) {
          const dialogRef = this.dialog.open(UpdateProfileComponent, { panelClass: "dialog-large", disableClose: true });

          dialogRef.afterClosed().subscribe({
            next: result => {
              localStorage.setItem('requested-for-2fa-activation', 'Y');

              if (result == true) {
                this.router.navigate(['/settings/profile'], { queryParams: { setup2FA: true } });
              }
            }
          });
        }
      },
      error: () => {
        this.loggedUser = null;
      }
    });
  }

  navigate(item) {
    this.sidenav.close();
    this.router.navigate([item.route]);
  }

  logout() {
    this.userService.logout();
  }
}
