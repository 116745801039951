import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TermsOfUse } from 'src/app/models/terms-of-use';
import { NotificationService } from 'src/app/services/notification.service';

@Component({
    selector: 'app-view-terms-of-use',
    templateUrl: './view-terms-of-use.component.html',
    styleUrls: ['./view-terms-of-use.component.scss']
})

export class ViewTermsOfUseComponent implements OnInit {
    termsOfUse: TermsOfUse[];

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<ViewTermsOfUseComponent>, private notificationService: NotificationService) { }

    ngOnInit() {
        if (this.data?.termsOfUse == null) {
            this.close();
            return;
        }

        this.termsOfUse = this.data.termsOfUse;
    }

    save() {
        if (this.termsOfUse.filter(i => !i.isAccepted).length > 0) {
            this.notificationService.warning('Todos os termos devem ser aceitos');
            return;
        }

        let acceptedTermsOfUse = this.termsOfUse.filter(i => i.isAccepted).map(i => i.id);
        this.dialogRef.close(acceptedTermsOfUse);
    }

    close() {
        this.dialogRef.close();
    }
}
