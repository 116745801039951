import { Component, ElementRef, Inject, OnInit, ViewChild } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { SelectAcceptanceTermComponent } from "src/app/compliance/acceptance-terms/select-acceptance-term/select-acceptance-term.component";
import { ViewAcceptanceTermComponent } from "src/app/compliance/acceptance-terms/view-acceptance-term/view-acceptance-term.component";
import { CompactUser } from "src/app/models/compact-user";
import { Post } from "src/app/models/post";
import { NotificationService } from "src/app/services/notification.service";
import { UserService } from "src/app/services/user.service";

@Component({
    selector: 'app-add-post',
    templateUrl: './add-post.component.html',
    styleUrls: ['./add-post.component.scss']
})

export class AddPostComponent implements OnInit {
    form: UntypedFormGroup;
    users: CompactUser[];
    filteredUsers: CompactUser[] = [];
    editMode: boolean;
    isStatic: any;
    isInPreviewMode: boolean = false;
    files: any[] = [];
    carrouselCurrentIndex: number = 0;
    carrouselInterval: any;

    @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
    @ViewChild('descriptionInput') descriptionInput: ElementRef<HTMLTextAreaElement>;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<AddPostComponent>, private notificationService: NotificationService,
        private userService: UserService, private dialog: MatDialog) {
        this.editMode = this.data?.post != null;
        this.isStatic = this.data.isStatic;
    }

    ngOnInit() {
        this.form = new UntypedFormGroup({
            title: new UntypedFormControl(null, [Validators.required]),
            description: new UntypedFormControl(null, [Validators.required]),
            userID: new UntypedFormControl(null),
            acceptanceTerm: new UntypedFormControl(null)
        });

        this.userService.getCompactUsers({ activeOnly: true, associatesOnly: true }).subscribe({
            next: users => {
                this.users = users;
            },
            error: error => {
                this.notificationService.error(error);
            }
        });

        if (this.editMode) {
            let post: Post = this.data.post;

            this.form.patchValue({ title: post.title, description: post.description });
        }
    }

    ngAfterViewInit() {
        if (this.editMode && this.descriptionInput != null) {
            this.descriptionInput.nativeElement.style.height = this.descriptionInput.nativeElement.scrollHeight + 'px';
        }
    }

    filterBySearch(value: string) {
        if (value.length <= 0) {
            this.filteredUsers = [];
            return;
        }

        this.filteredUsers = this.users.filter(u => u.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())).slice(0, 20);
    }

    applySearchSelection(user: CompactUser) {
        this.form.patchValue({ userID: user?.id });
        this.userInput.nativeElement.value = user?.name ?? '';

        if (user == null) {
            this.filteredUsers = [];
        }
    }

    uploadFile(e) {
        let files: File[] = e.target.files;

        for (var i = 0; i < files?.length; i++) {
            let file = files[i];

            if (file != null) {
                this.files.push(file);

                var path = URL.createObjectURL(file);
                file['path'] = path;
            }
        }

        this.files = this.files.filter(f => f);
    }

    removeFile(file: File) {
        const index = this.files.indexOf(file);

        if (index >= 0) {
            this.files.splice(index, 1);
        }
    }

    changePreviewMode(isInPreviewMode: boolean) {
        this.isInPreviewMode = isInPreviewMode;

        setTimeout(() => {
            if (this.userInput?.nativeElement != null) {
                this.userInput.nativeElement.value = this.users.find(u => u.id == this.form.value.userID)?.name ?? '';
            }
        }, 100);

        if (this.isInPreviewMode) {
            this.clearCarrouselTimer();
            this.setCarrouselTimer();
        }
        else {
            this.clearCarrouselTimer();
        }
    }

    setCarrouselTimer() {
        this.carrouselInterval = setInterval(() => {                
            this.carrouselCurrentIndex = this.carrouselCurrentIndex >= (this.files?.length - 1) ? 0 : (this.carrouselCurrentIndex + 1);
        }, 3000);
    }

    clearCarrouselTimer() {
        clearInterval(this.carrouselInterval);
    }

    selectCarrouselFile(file: File) {
        this.clearCarrouselTimer();
        this.carrouselCurrentIndex = this.files.indexOf(file);
        this.setCarrouselTimer();
    }

    viewAcceptanceTerm() {
        if (this.form.value.acceptanceTerm != null) {
            this.dialog.open(ViewAcceptanceTermComponent, {
                panelClass: "dialog-extra-large", data: { acceptanceTerm: this.form.value.acceptanceTerm }
            });
        }
    }

    selectAcceptanceTerm() {
        const dialogRef = this.dialog.open(SelectAcceptanceTermComponent, { panelClass: 'dialog-large' });

        dialogRef.afterClosed().subscribe({
            next: acceptanceTerm => {
                if (acceptanceTerm) {
                    this.form.patchValue({ acceptanceTerm: acceptanceTerm });
                }
            }
        });
    }

    removeAcceptanceTerm() {
        this.form.patchValue({ acceptanceTerm: null });
    }

    save() {
        if (this.form.invalid) {
            this.notificationService.warning('Existem campos não preenchidos.');
            return;
        }

        if (this.editMode) {
            this.dialogRef.close({ title: this.form.value.title, description: this.form.value.description });
        }
        else {
            let formData = new FormData();
            formData.append('title', this.form.value.title);
            formData.append('description', this.form.value.description);
            formData.append('isStatic', this.isStatic);

            for (var i = 0; i < this.files.length; i++) {
                formData.append('files', this.files[i]);
            }

            if (this.form.value.userID) {
                formData.append('userID', this.form.value.userID);
            }

            if (this.form.value.acceptanceTerm) {
                formData.append('acceptanceTermID', this.form.value.acceptanceTerm.id);
            }

            this.dialogRef.close(formData);
        }
    }

    cancel() {
        this.dialogRef.close();
    }

    isFileImage(fileType: string) {
        switch (fileType) {
            case 'image/png':
                return true;
            case 'image/jpeg':
                return true;
            case 'image/jpg':
                return true;
            default:
                return false;
        }
    }

    getPostDescription() {
        var description = this.form.value.description;
        return description?.trim().length > 0 ? this.form.value.description.replace(/\n/g, '<br>') : 'Descrição da publicação';
    }

    currentDateTime() {
        return Date.now();
    }
}